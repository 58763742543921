import makeRequest from './../../core/fetch';
import { ElasticFetcher } from '../../core/fetch';
import {generateParamsFromObject} from "../../core/library/url";
import store from "../../core/library/store";
import {LanguageStorageKey} from "../../config";

export function loadDocuments(type = '', page = 1, filter = {}, sort = '-createdAt', limit = 20) {
    let includeIds = filter.includeIds || [];
    if (filter.includeIds) {
        delete filter.includeIds;
    }

    if (store.get(LanguageStorageKey) && type !== 'languages') {
        filter = filter || {};
        filter.language = store.get(LanguageStorageKey);
    }

    let prepareFilter = generateParamsFromObject(filter);
    if (Array.isArray(includeIds) && includeIds.length > 0) {
        let prepare = includeIds.map(i => `includeIds=${i}`);
        prepareFilter = `${prepareFilter !== "" ? `${prepareFilter}` : ''}&${prepare.join('&')}`;
    }
    return makeRequest(`documents?type=${type}&page=${page}&${prepareFilter}&sort=${sort}&limit=${limit}`, `GET`);
}

export function updateDocument(id = '', data = {}) {
    if(window.user && window.user.permissions && window.user.permissions.indexOf('DEVELOPER_LOG') + 1 > 0) {
        window.documentUpdateHistory = window.documentUpdateHistory || [];
        window.documentUpdateHistory.push({time: (new Date()).getTime(),action: 'update document', id, data});
    }
    return makeRequest(`documents/${id}`, `PUT`, data);
}

export function getDocumentInfo(id = '') {
    return makeRequest(`documents/${id}`, `GET`);
}

export function createDocument(data = {}) {
    return makeRequest(`documents`, `POST`, data);
}

export function deleteDocument(id = '') {
    return makeRequest(`documents/${id}`, `DELETE`);
}

export function deleteDocumentRegion(id = '') {
    return makeRequest(`regions/${id}`, `DELETE`);
}

export function getHistory(id = '') {
    return makeRequest(`history/${id}`, `GET`);
}

export function setDocumentLock(id = '') {
    return makeRequest(`locks/${id}`, `POST`);
}

export function removeLock(id = '') {
    return makeRequest(`locks/${id}`, `DELETE`);
}

export function removeUserLock(id = '') {
    return makeRequest(`locks/${id}`, `PUT`);
}

export function getStash(id) {
    return makeRequest(`documents/stash/${id}?src=browser`, `GET`);
}

export function updateStash(id, data) {
    return makeRequest(`documents/stash/${id}?src=browser`, `PUT`, data);
}

export function deleteStash(id) {
    return makeRequest(`documents/stash/${id}?src=browser`, `DELETE`);
}

export function getWorkflow(statuses = [], page = 1, search = '', options = null, langFilter = null) {
    let r = [];
    if (statuses) {
        statuses.map(i => {
            r.push(`status=${i}`);
            return i;
        });
    }

    // let types = Config.WORKFLOW.WORKFLOW_FOR.map(i => {
    //     return `type=${i}`
    // });

    let types = [`type=Films`];

    let fOptions = [];
    if (options) {
        fOptions = options.map(i => `o=${i}`);
    }

    let langOptions = [];
    if (options) {
        if (store.get(LanguageStorageKey)) {
            langOptions = ['lng=' + store.get(LanguageStorageKey)]
        }
        // langOptions = langFilter.map(i => `lng=${i}`);
    }

    return makeRequest(`workflow?page=${page}${search !== '' ? `&search=${encodeURIComponent(search)}` : ''}&${r.join('&')}&${types.join('&')}&${fOptions.join('&')}&${langOptions.join('&')}`, `GET`)
}

export function documentsElLoad(type = '', page = 1, filter = {}, sort = '-publishedAt', limit = 20, dbSearch = false) {
    let prepareFilter = [];
    if (filter && Object.keys(filter).length > 0) {

        if (store.get(LanguageStorageKey)) {
            filter = filter || {};
            filter.language = store.get(LanguageStorageKey);
        }


        if(filter.published !== undefined ) {
            prepareFilter.push('published=' + filter.published);
        }

        if (filter.prepublished) {
            prepareFilter.push('prepublished=true');
        }

        if (type !== 'Category' && type !== 'Tags') {
            if (filter.q) {
                prepareFilter.push('q=' + encodeURIComponent(filter.q));
            }
            if (filter.search) {
                prepareFilter.push('eq=' + encodeURIComponent(filter.search));
            }
        } else {
            if (filter.q) {
                prepareFilter.push('wis=' + encodeURIComponent(filter.q));
            }
            if (filter.search) {
                prepareFilter.push('wis=' + encodeURIComponent(filter.search));
            }
        }
        if (filter.workflow) {
            prepareFilter.push('wf=' + encodeURIComponent(filter.workflow));
        }
        if (filter.includeIds) {
            filter.includeIds.map(i => {
                prepareFilter.push('in=' + i);
                return i;
            });
        }

        if(filter.trend) {
            prepareFilter.push(`trend=` + filter.trend);
        }

        if (filter.excludeIds) {
            filter.excludeIds.map(i => {
                prepareFilter.push('ex=' + i);
                return i;
            });
        }

        if (filter.pulse) {
            prepareFilter.push('');
        }
        if (filter.language && type !== 'Languages') {
            prepareFilter.push(`language=${filter.language}`);
        }
    }

    if ((type === "Tag" || type === "Author") && dbSearch === true) {
        return loadDocuments(type, page, {search: filter.search}, sort, 700);
    } else {
        return ElasticFetcher(`document?sort=${sort}&limit=${limit}&page=${page}&type=${type}&${prepareFilter.join('&')}`);
    }
}


export function documentsElLoadDocs(type = '', page = 1, filter = {}, sort = '-createdAt', limit = 30) {
    let prepareFilter = [];

    if (filter && Object.keys(filter).length > 0) {
        if (filter.prepublished) {
            prepareFilter.push('prepublished=1');
        }
        if (filter.search) {
            prepareFilter.push('q=' + encodeURIComponent(filter.search));
        }
        if (filter.workflow) {
            prepareFilter.push('wf=' + encodeURIComponent(filter.workflow));
        }

        if (filter.tags) {
            prepareFilter.push('tags=' + filter.tags);
        }

        if (filter.category) {
            prepareFilter.push('category=' + filter.category);
        }

        if(filter.df || filter.dt) {
            prepareFilter.push(`range=publishedAt`);
            if (filter.df) {
                prepareFilter.push(`from=${filter.df}T00:00:00.00`);
            }
            if (filter.dt) {
                prepareFilter.push(`to=${filter.dt}T23:59:59.00`);
            }
        }



        if (typeof filter.published !== undefined && (filter.published === true || filter.published === false)) {
            prepareFilter.push(`published=${filter.published}`);
        }



        if (filter.includeIds) {
            filter.includeIds.map(i => {
                prepareFilter.push('in=' + i);
                return i;
            });
        }
        if (filter.excludeIds) {
            filter.excludeIds.map(i => {
                prepareFilter.push('ex=' + i);
                return i;
            });
        }
    }

    return ElasticFetcher(`document?limit=${limit}&sort=${sort}&page=${page}&type=${type}&${prepareFilter.join('&')}`);
}

export function addItem(data = {}) {
    return makeRequest(`/push`, `POST`, data);
}

export function loadPollQuestions(doc_id = '') {
    return makeRequest(`/polls/${doc_id}`, `GET`);
}

export function createQuestion(doc_id = '', data = {}) {
    return makeRequest(`/polls/${doc_id}`, `POST`, data);
}

export function deleteQuestion(doc_id = '') {
    return makeRequest(`/polls/${doc_id}`, `DELETE`);
}

export function updateQuestion(doc_id = '', data = {}) {
    return makeRequest(`/polls/${doc_id}`, `PUT`, data);
}
export function updateQuestionSort(data = {}) {
    return makeRequest(`/polls/sort`, `PUT`, data);
}

export function uploadRegionsSites(doc_id) {
    return makeRequest(`/regions/${doc_id}`, 'POST');
}

export function loadMapReport(categories, date_first, date_second) {
    let filter = [];
    if (categories) {
        for(let i in categories) {
            filter.push(`category=${categories[i]}`);
        }
    }
    if (date_first) {
        filter.push(`date_start=${date_first}`);
    }
    if (date_second) {
        filter.push(`date_end=${date_second}`);
    }
    return makeRequest(`/reports/map?${filter.join('&')}`);
}
